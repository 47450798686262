import './App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { faAt, faUser } from '@fortawesome/free-solid-svg-icons'

function App () {
  return (
    <div className="App">
      <div className={'main'}>
        <p className={'name'}>Timofey Geraymovich</p>
        <p className={'position'}>backend developer (PHP, Symfony)</p>
        <div>
          <div className={'link'}>
            <a href={'https://github.com/timager'} target={'_blank'}
               rel="noreferrer">
              <FontAwesomeIcon icon={faGithub}/><span>My github profile</span>
            </a>
          </div>
          <div className={'link'}>
            <a href={'mailto:dev@timager.com?subject=Mail from timager.com'}
               target={'_blank'}
               rel="noreferrer">
              <FontAwesomeIcon icon={faAt}/><span>Email me</span>
            </a>
          </div>
          <div className={'link'}>
            <a href={'https://t.me/timager'} target={'_blank'}
               rel="noreferrer">
              <FontAwesomeIcon icon={faTelegram}/><span>My telegram</span>
            </a>
          </div>
          <br/>
          <div className={'resume'}>
            <FontAwesomeIcon icon={faUser}/>
            <a className={'link'}
               href={'https://spb.hh.ru/resume/deb2a752ff028335f50039ed1f687a354f3333'}
               target={'_blank'}
               rel="noreferrer">hh.ru
            </a>
            <a className={'link'}
               href={'https://career.habr.com/timager'}
               target={'_blank'}
               rel="noreferrer">career.habr.com
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
